import React, { useState, useEffect } from 'react';

function Lunch(props){
    return (
        <div style={{
            fontSize: 'calc(8px + 2vmin)',
            paddingLeft: '10px',
        }} className='LunchBlock'>
            <div style={{textAlign: 'justify'}}>
            &nbsp;&nbsp;Бизнес-ланч – идеальное решение для деловых встреч и перекуса в рабочее время. <br/>&nbsp;&nbsp;Насладитесь аппетитным и сытным обедом, включающим суп, основное блюдо, салат и напиток на выбор(морс, компот, чай), всего за <span style={{color: '#c3262b',marginRight: "10px"}}> 349 ₽</span>с 12:00 до 17:00.<br/><hr/>
            &nbsp;&nbsp;Ежедневно на выбор 4 разных салата, 4 супа, 6 горячих блюд. На гарнир мы подаём пюре, гречу, рис или спагетти.  
            </div>

        </div>
    )
}

export default Lunch;