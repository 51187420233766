import './Main.css';
import React, { useState, useEffect, useRef } from 'react';
import { useMedia } from 'react-use';
import Menu from './Menu.js'
import Info from './Info.js'
import Lunch from './Lunch.js'

function Main() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const isMobile = useMedia('(max-width: 480px)');
    
    return (
        <div className="Main">
            <div id="MainHeader" className="Header">
                <div className="TitleHeader">
                    <img 
                        src="/images/logo.png"
                        height="64"
                    ></img>
                    <p className="TitleText"><b>Venci Кафе</b></p>
                </div>
                <div className='FoodQuickBtns'>
                    <a href="#Меню">Меню</a>
                    <a href="#About">О нас</a>
                </div>
                <hr/>
                <Menu type="menuQuickBtns" menuKey="main"/>
                <hr/>
            </div>
            
            <div className='MainBody'>
                <p className='ParagraphText' id='Меню'><b>Наше меню</b></p>
                    <p className="FoodTitle">Бизнес ланч</p>
                    <Lunch/>
                    
                    <Menu type="menu" menuKey="main" menuLimit={isMobile ? '3' : '4'}/>

                <br/>
                <hr/>
                <p className='ParagraphText' id='About'><b>О нас</b></p>
                    <Info/>
            </div>

        </div>
    );
}

export default Main;
