import React, { useState, useEffect } from 'react';

const FoodImage = ({ filePath, width = "auto", height = "auto" }) => {
    var placeholderPath = '/images/food_placeholder.jpg'
    const [src, setSrc] = useState(null);

    useEffect(() => {
    const image = new Image();
    image.onload = () => {
        setSrc(filePath);
    };
    image.onerror = () => {
        setSrc(placeholderPath);
    };
    image.src = filePath;
    }, [filePath, placeholderPath]);

    return <img src={src} alt="" width={width} height={height}/>;
};

export default FoodImage;