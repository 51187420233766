import React, { useState, useEffect } from 'react';

function Info(props){

    return (
        <div>
            <div className='InfoText'>
            <span style={{paddingLeft: "40px"}}></span>Кафе "Venci" работает на Московском проспекте напротив сада Олимпия. <br/>
            <span style={{paddingLeft: "40px"}}></span>В лаконичном меню собрали простые домашние блюда: здесь подают вареники и пельмени, пара салатов, запеченную курицу и свинину. <br/>
            <span style={{paddingLeft: "40px"}}></span>Каждый день с открытия и до полудня сервируют классические завтраки: из утреннего меню можно выбрать сырники и кашу. <br/>
            </div>
            <br/>

            <div style={{position: 'relative', overflow: 'hidden'}}><a href="https://yandex.ru/maps/org/venci/30122126439/?utm_medium=mapframe&utm_source=maps" style={{color: '#eee', fontSize: '12px', position: 'absolute', top: '0px'}}>Venci</a><a href="https://yandex.ru/maps/2/saint-petersburg/category/cafe/184106390/?utm_medium=mapframe&utm_source=maps" style={{color: '#eee', fontSize: '12px', position: 'absolute', top: '14px'}}>Кафе в Санкт‑Петербурге</a><iframe src="https://yandex.ru/map-widget/v1/?ll=30.318227%2C59.912582&mode=poi&poi%5Bpoint%5D=30.318005%2C59.912658&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D30122126439&utm_source=share&z=18" width="100%" height={400} allowFullScreen="true" style={{position: 'relative'}} /></div>
            <div style={{width: '100%', height: '800px', overflow: 'hidden', position: 'relative'}}><iframe style={{width: '100%', height: '100%', border: '1px solid #e6e6e6', borderRadius: '8px', boxSizing: 'border-box'}} src="https://yandex.ru/maps-reviews-widget/30122126439?comments" /><a href="https://yandex.ru/maps/org/venci/30122126439/" target="_blank" style={{boxSizing: 'border-box', textDecoration: 'none', color: '#b3b3b3', fontSize: '10px', fontFamily: 'YS Text,sans-serif', padding: '0 16px', position: 'absolute', bottom: '8px', width: '100%', textAlign: 'center', left: 0, overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', maxHeight: '14px', whiteSpace: 'nowrap'}}>Venci на карте Санкт‑Петербурга — Яндекс&nbsp;Карты</a></div>
            <br/>

            <img
                className="AboutPhotos"
                src="/images/image1.png"
            ></img>
            <br/>
            <img
                className="AboutPhotos"
                src="/images/image2.png"
            ></img>
        </div>
    )
}

export default Info;