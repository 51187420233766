import React, { useState, useEffect } from 'react';
import FoodImage from './FoodImage';
const transliteration = require('transliteration');

function encodeFoodId(foodName){
    return foodName.toLowerCase().replace(/ /g, '_');
}

const formatDishName = (dishName) => {
    return transliteration
      .slugify(dishName)
      .toLowerCase()
      .replace(/[^\w\s]/gi, '')
      .replace(/\s+/g, '_');
  };

function getFoodImage(name){
    
}

function Menu(props){
    const { type } = props;
    const { menuKey } = props
    const { foodName } = props;
    const [menu, setMenu] = useState({});

    useEffect(() => {
        fetch('/data/menu.json')
          .then(response => response.json())
          .then(json => setMenu(json))
          .catch(error => console.error(error));
      }, []);

    if (Object.keys(menu).length != 0){
        if (type == 'menuQuickBtns'){
            const jsx = []

            for (let lunch_type in menu[menuKey]){
                jsx.push(
                        <a
                            href={"#"+encodeFoodId(lunch_type)}
                        >{lunch_type}</a>
                )
            }

            return (
                <div className='FoodQuickBtns'>
                    {jsx}
                </div>
            )
        }

        const { menuLimit } = props;
        if (type == 'menu'){
            const jsx = []
            
            for (let lunch_type in menu[menuKey]){
                let dishes = menu[menuKey][lunch_type]
                let food_jsx = []
                
                let food_main_block = []
                let food_blocks = []
                let multi_food_blocks = []
                let key = 0
                let counter = 0

                for (let i=0; i != dishes.length; i++){
                    if (typeof(dishes[i]) == 'object'){
                        let title = dishes[i]['title']
                        let options = dishes[i]['options']
                        let multi_dishes = []

                        for (let option in options){
                            multi_dishes.push(
                                //ето типа имя - цена, с цветом нужным и по центру
                                <p className='MultiFoodText' style={{textAlign: 'justify'}}>{option} - <span style={{color: '#c3262b'}}>{options[option]} ₽</span></p>
                            )

                        }

                        if (title === "") title = dishes[i]['image']

                        multi_food_blocks.push(
                            <div className='MultiFoodBlock'>
                                <p className='MultiFoodTitle'>{title}</p>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <FoodImage
                                        filePath={`/images/food/${title}.png`}
                                        width="25%"
                                        height="25%"
                                        style={{
                                            marginRight: "10px"
                                        }}
                                    />

                                    <div style={{display: 'flex', flexDirection: 'column', width:'100%', marginLeft: '10px'}}>
                                        {multi_dishes}
                                    </div>

                                </div>
                            </div>
                        )
                    }
                }

                for (let i=0; i != dishes.length; i++){
                    if (typeof(dishes[i]) == 'object') continue
                    if (food_blocks.length == 0) food_blocks.push([])
                    
                    food_blocks[key].push(
                        <div className='FoodBlock' id={dishes[i]}>
                            <FoodImage
                                filePath={`/images/food/${dishes[i]}.png`}
                                width="100%"
                            />
                            <p>{dishes[i]}</p>
                            <div style={{
                                height: "100%"
                            }}></div>
                            <div>
                                <p className="FoodCost">{menu['food'][dishes[i]]['cost']} ₽</p>
                            </div>
                        </div>
                    )

                    counter += 1
                    if (counter == menuLimit){
                        counter = 0
                        key += 1
                        food_blocks.push([])
                    }
                }

                console.log(food_blocks)
                if (food_blocks.length > 0){
                    while(food_blocks[key].length != menuLimit){
                        food_blocks[key].push(
                            <div style={{
                                width: "100%",
                                margin: "5px",
                                padding: "10px",
                            }}></div>
                        )
                    }
    
                    for (let i in food_blocks){
                        food_main_block.push(
                            <div className="FoodBlocks">
                                {food_blocks[i]}
                            </div>
                        )
                    }
                }


                jsx.push(
                    <div><div id={encodeFoodId(lunch_type)} className="FoodHeader">
                        <p className="FoodTitle">{lunch_type}</p>
                    </div>
                    {multi_food_blocks}
                    {food_main_block}</div>
                )
            }

            return (<div>{jsx}</div>)
        }
    }
}

export default Menu;